@import 'src/assets/styles/palette';
@import 'src/assets/styles/mixins';

.officeVideoContainer {
  padding: 0 24px;
  max-width: 1248px;
  margin: 0 auto 160px;

  @include laptop {
    margin-bottom: 120px;
  }

  @include tablet {
    margin-bottom: 40px;
    padding: 0 12px;
  }
}

.videoElement {
  width: 100%;
  height: 500px;

  @include laptop {
    height: 500px;
  }

  @include tablet {
    min-height: 300px;
    height: 100%;
  }
}