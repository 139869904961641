@import 'src/assets/styles/palette';
@import 'src/assets/styles/mixins';

.work_container {
  padding: 0 24px;
  max-width: 1248px;
  margin: 0 auto 160px;
  @include laptop {
    margin-bottom: 120px;
  }
  @include tablet {
    margin-bottom: 40px;
    padding: 0 12px;
  }
}

.work_title {
  @include titleGradient;
  padding-bottom: 40px;
  @include laptop {
    padding-bottom: 24px;
  }
}

.work_cards {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  @include scrollbar;
  margin-bottom: 80px;
  @include laptop {
    margin-bottom: 60px;
  }
  @include tablet {
    margin-bottom: 24px;
  }
}

.work_cards_item {
  padding: 54px 24px;
  width: 100%;
  display: inline-block;
  border-radius: 20px;
  box-shadow: 0 24px 24px 0 $gray1;
  border: 2px solid $borderColor;
  @include tablet {
    padding: 54px 18px;
  }
}

.work_cards_item_title {
  margin-bottom: 16px;
  text-align: center;
  @include tablet {
    min-width: 100px;
  }
}

.work_cards_item_subtitle {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  text-align: center;
  min-width: 100px;
}

.work_container_end {
  position: relative;
  display: flex;
  align-items: center;
  gap: 24px;
  margin-bottom: 160px;
  @include laptop {
    margin-bottom: 120px;
  }
  @include tablet {
    flex-direction: column;
    margin-bottom: 40px;
  }
}
.work_bg_right {
  position: absolute;
  right: -24px;
  z-index: -1;
  @include laptop {
    right: -12px;
    max-width: 50%;
  }
  @include tablet {
    max-width: 30%;
  }
}

.work_bg_left {
  position: absolute;
  left: 0;
  z-index: -1;
}

.work_container_end_text {
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  @include laptop {
    text-align: center;
  }
}

.work_container_end_item {
  display: flex;
  align-items: center;
  gap: 24px;
  max-width: 285px;
  @include tablet {
    max-width: 100%;
    flex-direction: column;
  }
}

.soon_container {
  position: relative;
  background: $gradientOrangeColor;
  border-radius: 20px;
  padding: 80px;
  @include laptop {
    padding: 60px;
  }
}

.soon_container_bg {
  position: absolute;
  top: 0;
  right: 0;
  @include tablet {
    display: none;
  }
}

.soon_container_title {
  font-size: 40px;
  font-weight: 700;
  line-height: 44px;
  margin-bottom: 40px;
  @include tablet {
    font-size: 32px;
    line-height: 36px;
    margin-bottom: 24px;
  }
}

.soon_cards {
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(3, auto);
  grid-template-rows: repeat(3, auto);
  @include laptop {
    gap: 20px;
    grid-template-columns: repeat(2, auto);
    grid-template-rows: repeat(5, auto);
  }
  @include tablet {
    gap: 16px;
    grid-template-columns: auto;
    grid-template-rows: auto;
  }
}

.soon_cards_item {
  display: flex;
  gap: 14px;
}

.soon_cards_item_icon {
  width: 14px;
  height: 20px;
  margin-top: 4px;
  @include tablet {
    margin-top: 2px;
  }
}

.soon_cards_item_title {
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  @include tablet {
    font-size: 14px;
    line-height: 22px;
  }
}
