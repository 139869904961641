@import 'src/assets/styles/palette';
@import 'src/assets/styles/mixins';

.status_container {
  margin-bottom: 24px;
  @include laptop {
    margin-bottom: 16px;
  }
  @include tablet {
    margin-bottom: 12px;
  }
}

.status_top {
  display: flex;
  justify-content: space-between;
  gap: 16px;
  align-items: center;
  margin-bottom: 24px;
  @include laptop {
    flex-direction: column;
    align-items: start;
  }
  @include tablet {
    gap: 12px;
  }
}

.status_top_title {
  margin-bottom: 0;
}

.status_top_info {
  @include tablet {
    width: 100%;
  }
}

.seller_top {
  display: flex;
  justify-content: space-between;
  gap: 16px;
  align-items: center;
  margin-bottom: 24px;
  @include laptop {
    flex-direction: column;
    align-items: start;
  }
  @include tablet {
    gap: 12px;
  }
}

.seller_top_title {
  margin-bottom: 0;
}

.seller_top_info {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  color: $blueLight;
}

.seller_content {
  display: flex;
  gap: 24px;
  justify-content: space-between;
  @include laptop {
    flex-direction: column;
  }
}

.seller_title {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 16px;
  @include tablet {
    margin-bottom: 8px;
  }
}

.seller_ul {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: $blueLight;
  margin-bottom: 24px;
}

.seller_amount_list {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  .seller_amount_card {
    width: calc(50% - 8px);
    align-self: center;
    @include tablet {
      width: 100%;
    }
    .seller_amount_line_full {
      position: relative;
      height: 4px;
      border-radius: 2px;
      width: 100%;
      margin-bottom: 12px;
      background-color: $white1;
      .seller_amount_line {
        max-width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        height: 4px;
        border-radius: 2px;
        background: $gradientOrangeColor;
      }
    }
    .seller_amount_numbs {
      display: flex;
      justify-content: space-between;
      gap: 4px;
      .seller_amount_numb {
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
      }
    }
  }
}

.seller_amount_title {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: $blueLight;
  margin-bottom: 8px;
}

.seller_right {
  width: 250px;
  padding: 28px;
  border-radius: 16px;
  background-color: $white2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @include laptop {
    width: 100%;
    padding: 80px 16px;
  }
  @include tablet {
    padding: 32px 16px;
  }
  .seller_right_subtitle {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    text-align: center;
    color: $blueLight;
  }
  .seller_right_title {
    font-size: 40px;
    font-weight: 700;
    line-height: 44px;
    text-align: center;
    text-wrap: nowrap;
  }
  .seller_right_title_small {
    font-size: 20px;
  }
}
