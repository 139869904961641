@import 'src/assets/styles/palette';
@import 'src/assets/styles/mixins';

.card_info {
  position: relative;
  display: flex;
  border-radius: 20px;
  background: $gradientBlueGray02Color;
  border-image-source: $gradientBorderColor;
  box-shadow: $boxShadow;
  margin-bottom: 160px;
  @include laptop {
    margin-bottom: 120px;
    flex-direction: column;
    gap: 24px;
  }
  @include tablet {
    margin-bottom: 64px;
  }
}

.cards_item_img_container {
  width: 60%;
  padding-top: 40px;
  text-align: center;
  background-image: url(../../../../assets/images/icons/ellipse-color-2.png);
  background-repeat: no-repeat;
  background-position: center;
  @include laptop {
    width: 100%;
  }
  @include tablet {
    background-size: cover;
  }
}

.cards_item_img {
}

.cards_item_img_bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  border-radius: 20px;
  @include laptop {
    left: 50%;
  }
}

.cards_item_text {
  width: 40%;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  align-self: center;
  padding-right: 80px;
  @include laptop {
    width: 100%;
    text-align: center;
    padding: 0 20px 20px;
  }
}
