@import 'src/assets/styles/palette';
@import 'src/assets/styles/mixins';

.pagination {
  display: flex;
  list-style: none;
  gap: 4px;
  flex-wrap: wrap;
}

.page {
  background-color: $white2;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  text-align: center;
  border-radius: 8px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  transition: background-color 0.4s;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: $blueLight;
    color: $white;
  }
}

.arrow_button {
  cursor: pointer;
  border-radius: 8px;
  color: $blueLight;
  width: 40px;
  height: 40px;
  text-align: center;
  background-color: $white2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.arrow_button_next {
  margin-left: 28px;
  @include laptop {
    margin-left: 16px;
  }
}

.arrow_button_prev {
  margin-right: 28px;
  @include laptop {
    margin-right: 16px;
  }
}


.break {
  width: 40px;
  height: 40px;

  &_line {
    border-radius: 8px;
    cursor: pointer;
    transition: 0.2s;
    width: 40px;
    height: 40px;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    &:before {
      content: "";
      width: 0;
      height: 0;
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
}

.page_active {
  background: $gradientOrangeColor;
  color: $white;
}

.prev_arrow {
  transform: rotate(90deg);
}

.next_arrow {
  transform: rotate(-90deg);
}

.disabled {
  background-color: $gray;
  cursor: not-allowed;
}
