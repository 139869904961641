@import './palette';

// Media request mixins MobileFirst

@mixin desktop {
  @media screen and (max-width: 1230px) {
    @content;
  }
}

@mixin laptop {
  @media screen and (max-width: 1024px) {
    @content;
  }
}

@mixin tablet {
  @media screen and (max-width: 768px) {
    @content;
  }
}

@mixin mobile {
  @media screen and (max-width: 576px) {
    @content;
  }
}

@mixin scrollbar($bg: $gray, $bgThumb: $borderColor, $bgActive: $grey5) {
  &::-webkit-scrollbar {
    width: 6px;
    height: 0;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: $bg;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $bgThumb;
    border-radius: 4px;

    &:hover {
      background-color: $bgActive;
    }
  }
}

@mixin titleGradient {
  white-space: pre-wrap;
  background: $gradientTextColor;
  -webkit-background-clip: text;
  -webkit-text-fill-color: $transparent;
  position: relative;
  font-size: 40px;
  font-weight: 700;
  line-height: 44px;
  margin-bottom: 40px;
  @include tablet {
    font-size: 24px;
    line-height: 26px;
    margin-bottom: 32px;
  }
}
