@import 'src/assets/styles/palette';
@import 'src/assets/styles/mixins';

.question_container {
  background: $white;
  border-radius: 24px;
  box-shadow: $boxShadowMain;

  &_home {
    background: $gradientBlueGray02Color;
    border-image-source: $gradientBorderColor;
    box-shadow: $boxShadow;
    border-radius: 20px;

  }
}

.question_card_close {
  max-height: 0;
  opacity: 0;
  padding: 0;
  transition: max-height 0.3s ease, opacity 0.3s ease, padding 0.3s ease;
}

.question_card {
  max-height: fit-content;
  opacity: 1;
  gap: 12px;
  padding: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &_answer {
    border-bottom: solid 1px $grey5;
  }
  &_home {
    padding: 40px;
    @include tablet {
      padding: 24px;
    }
  }
}

.question_subcard {
  padding-top: 0;
}


.question_card_text {
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  @include tablet {
    font-size: 14px;
    line-height: 22px;
  }
}

.question_card_subtitle {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  color: $blueLight;
  white-space: break-spaces;
}


.question_card_icon_container {
  padding: 12px;
  border-radius: 8px;
  border: 1px solid $borderColor;
  background-color: $white2;
  &_home{
    background-color: $transparent;
  }
}

.question_card_icon {
  transform: rotate(0);
  transition: transform 0.3s ease;
}

.question_card_icon_open {
  transform: rotate(45deg);
}
