@import 'src/assets/styles/palette';
@import 'src/assets/styles/mixins';

.video_call_container {
  padding: 0 24px;
  max-width: 1248px;
  margin: 0 auto 160px;
  @include laptop {
    margin-bottom: 120px;
  }
  @include tablet {
    margin-bottom: 40px;
    padding: 0 12px;
  }
}

.video_call_title {
  @include titleGradient;
  padding-bottom: 40px;
  @include laptop {
    padding-bottom: 24px;
  }
}

.video_call_bg_left {
  position: absolute;
  left: -58px;
  top: -100px;
  z-index: -1;
}

.video_call_bg_right {
  position: absolute;
  right: -24px;
  top: -100px;
  z-index: -1;
  @include laptop {
    display: none;
  }
}

.video_call_bg_center {
  position: absolute;
  right: 50%;
  top: -100px;
  z-index: -1;
  @include tablet {
    right: -12px;
    top: 100px;
  }
}
