@import 'src/assets/styles/palette';
@import 'src/assets/styles/mixins';

.select_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  input {
    border: none;
  }
}
