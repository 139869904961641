@import 'src/assets/styles/palette';
@import 'src/assets/styles/mixins';

.questions_container {
  padding: 0 24px;
  max-width: 1248px;
  margin: 0 auto 160px;
  @include laptop {
    margin-bottom: 120px;
  }
  @include tablet {
    margin-bottom: 40px;
    padding: 0 12px;
  }
}

.questions_title {
  @include titleGradient;
  @include laptop {
    padding-bottom: 24px;
  }
}

.questions_cards {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
