@import 'src/assets/styles/palette';
@import 'src/assets/styles/mixins';

.container {
  background-color: $white;
  border-radius: 24px;
  box-shadow: $boxShadowMain;
  padding: 24px;
  @include tablet {
    border-radius: 16px;
    padding: 12px;
  }
}
