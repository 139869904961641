@import 'src/assets/styles/palette';
@import 'src/assets/styles/mixins';

.body {
  overflow: hidden;
}

.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background-color: $white;
  border-radius: 24px;
  padding: 40px;

  min-width: 350px;
  max-width: 500px;
  width: 100%;
  max-height: 100%;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1000;
  background: $blackTransparent;
  align-items: center;
  display: flex;
  justify-content: center;
}

.modal_close_button {
  position: absolute;
  right: 24px;
  top: 24px;
}
