@import 'src/assets/styles/palette';
@import 'src/assets/styles/mixins';

.main_half_first {
  min-height: 650px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 20px;
  padding: 40px;
  max-width: 488px;
  background: $gradientBlueGray02Color;
  border-image-source: $gradientBorderColor;
  box-shadow: $boxShadow;
  border-radius: 20px;
  width: 100%;
  z-index: 1;
  @include laptop {
    margin-right: 0;
  }
  @include tablet {
    padding: 24px 12px;
  }
}

.main_half_first_forger {
  min-height: auto;
}

.main_image {
  margin-bottom: 32px;
  align-self: center;
}

.main_title {
  white-space: pre-wrap;
  @include titleGradient;
  text-align: center;
  margin-bottom: 16px;
  @include tablet {
    margin-bottom: 12px;
  }
}

.main_subtitle {
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;

  text-align: center;
  margin-bottom: 24px;
}

.main_subtitle_btn {
  font-weight: 400;
  color: $violetLight1;
}

.main_subtitle_big {
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  @include tablet {
    font-size: 14px;
    line-height: 22px;
  }
}

.main_form {
  display: flex;
  flex-direction: column;
  gap: 16px;
  @include tablet {
    gap: 12px;
  }
}

.main_btn {
  margin-top: 8px;
  margin-bottom: 16px;
  @include tablet {
    margin-top: 4px;
    margin-bottom: 12px;
  }
}

.label_check {
  color: $violet;
  @include tablet {
    white-space: pre-wrap;
    background: $gradientTextColor;
    -webkit-background-clip: text;
    -webkit-text-fill-color: $transparent;
  }
}
