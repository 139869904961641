@import 'src/assets/styles/palette';
@import 'src/assets/styles/mixins';

.agent_container {
  position: relative;
  display: flex;
  gap: 40px;
  padding: 0 24px;
  max-width: 1248px;
  margin: 0 auto 160px;
  @include laptop {
    margin-bottom: 120px;
  }
  @include tablet {
    flex-direction: column;
    gap: 32px;
    margin-bottom: 40px;
    padding: 0 12px;
  }
}

.agent_container_first {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-self: center;
  @include tablet {
    width: 100%;
  }
}

.agent_title {
  @include titleGradient;
  height: 84px;
  display: flex;
  justify-content: left;
  @include tablet {
    height: 50px;
    justify-content: center;
  }
}

.agent_title_bg {
  position: absolute;
  z-index: -1;
  max-height: 172px;
  width: calc(100% - 24px);
  @include tablet {
    height: 70px;
    width: calc(100% - 12px);
  }
}

.agent_percent {
  background: $gradientBlueGray05Color;
  border-image-source: linear-gradient(180deg, $gradientBorderWhiteColor 0%);
  box-shadow: $boxShadow;
  border-radius: 20px;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  @include tablet {
    margin-bottom: 16px;
  }
}

.agent_percent_border {
  width: 1px;
  height: 58px;
  background: $gradientBorderColor;
}

.agent_percent_item {
  padding: 40px 20px;
  width: 50%;
}

.agent_percent_item_title {
  @include titleGradient;
  text-align: center;
  @include tablet {
    font-size: 32px;
    line-height: 34px;
  }
}

.agent_percent_item_subtitle {
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  text-align: center;
  white-space: nowrap;
  @include tablet {
    font-size: 12px;
    line-height: 18px;
  }
}

.agent_container_first_text {
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  @include tablet {
    text-align: center;
  }
}

.agent_container_second {
  width: 60%;
  @include tablet {
    width: 100%;
  }
  tr td:last-child {
    text-wrap: nowrap;
  }
}
