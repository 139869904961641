@import 'src/assets/styles/palette';
@import 'src/assets/styles/mixins';

.burger {
  z-index: -1;
  position: absolute;
  height: 100vh;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  width: 100%;
  padding: 0;
  top: 0;
  background-color: $grey2;
}
