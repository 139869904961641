@import 'src/assets/styles/palette';
@import 'src/assets/styles/mixins';

.cards {
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
  margin-bottom: 40px;
  @include tablet {
    gap: 12px;
  }
}

.cards_item {
  display: flex;
  gap: 24px;
  background: $gradientBlueGray02Color;
  border-image-source: $gradientBorderColor;
  box-shadow: $boxShadow;
  border-radius: 20px;
  width: calc(50% - 12px);
  padding: 40px 20px;
  @include laptop {
    width: 100%;
  }
  @include tablet {
    flex-direction: column;
  }
}

.cards_item_img {
  height: fit-content;
  width: fit-content;
  @include tablet {
    align-self: center;
  }
}

.cards_item_title {
  margin-bottom: 24px;
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  @include tablet {
    font-size: 14px;
    line-height: 22px;
  }
}

.cards_item_subtitle {
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  @include tablet {
    font-size: 14px;
    line-height: 22px;
  }

}

.cards_item_ul {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.cards_item_li {
  display: flex;
  gap: 16px;
}

.cards_item_li_img {
  margin-top: 4px;
  margin-bottom: auto;
}
