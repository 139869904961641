@import 'src/assets/styles/palette';
@import 'src/assets/styles/mixins';

.burger {
  z-index: 2;
  position: absolute;
  background: $gradientBlueGray05Color;
  box-shadow: $boxShadow;
  height: 68px;
  display: flex;
  gap: 20px;
  width: 100%;
  padding: 0 24px;
  backdrop-filter: blur(12px);
  @include tablet {
    background: $violet;
    flex-direction: column;
    justify-content: space-between;
    padding: 12px;
  }
}
