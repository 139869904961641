@import 'src/assets/styles/palette';
@import 'src/assets/styles/mixins';

.main_container {
  background-image: url(../../../assets/images/main_laptop.png);
  background-position: top left calc((100% - 1000px) / 2 + 960px), top center;
  background-repeat: no-repeat;
  @include tablet {
    background-image: none;
  }
}

.main_content {
  display: flex;
  align-items: center;
  min-height: 70vh;
  padding: 0 24px;
  max-width: 1248px;
  margin: 0 auto;
  @include tablet {
    padding: 0 12px;
  }
  @include mobile {
    flex-direction: column-reverse;
  }
}

.main_half_first {
  width: 100%;
  z-index: 1;
  @include tablet {
    text-align: -webkit-center;
  }
}

.main_title {
  @include titleGradient;
  font-size: 60px;
  line-height: 66px;
  margin-bottom: 32px;
  @include tablet {
    text-align: center;
    margin-bottom: 24px;
    font-size: 32px;
    line-height: 36px;
  }
}

.main_subtitle {
  font-size: 24px;
  font-weight: 700;
  line-height: 26px;
  margin-bottom: 48px;
  @include tablet {
    text-align: center;
    margin-bottom: 40px;
    font-size: 14px;
    line-height: 21px;
  }
}

.main_half_second {
  width: 100%;
  height: 800px;
  position: relative;
  @include tablet {
    height: 50vh;
    margin-left: 0;
    max-height: 500px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: right;
    text-align: right;
  }
}

.main_half_second_img {
  height: 100%;
  margin-right: -12px;
  @include tablet {
    height: auto;
    max-height: 500px;
    max-width: 100%;
  }
}

.ellipse_1 {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  max-width: 100%;
}

.ellipse_2 {
  position: absolute;
  bottom: -3%;
  right: 0;
  z-index: -1;
  max-width: 80%;
  @include mobile {
    display: none;
  }
}

.main_btn {
  @include tablet {
    margin-bottom: 25px;
  }
}
