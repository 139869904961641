@import 'src/assets/styles/palette';
@import 'src/assets/styles/mixins';

.header_navigation {
  display: flex;
  gap: 40px;
  @include tablet {
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-top: 64px;
  }
}

.header_navigation_link {
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  align-self: center;
  @include tablet {
    text-align: left;
    width: 100%;
  }
}
