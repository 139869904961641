@import 'src/assets/styles/palette';
@import 'src/assets/styles/mixins';

.container {
  width: 100%;
  .container_first {
    text-align: -webkit-center;
    margin-top: 100px;
    @include laptop {
      margin-top: 80px;
    }
    .container_title {
      white-space: pre-wrap;
      font-size: 40px;
      font-weight: 700;
      line-height: 44px;
      text-align: center;
      margin-bottom: 32px;
      @include tablet {
        font-size: 24px;
        line-height: 26px;
        margin-bottom: 16px;
      }
    }
    .container_subtitle {
      white-space: pre-wrap;
      font-size: 18px;
      font-weight: 500;
      line-height: 28px;
      text-align: center;
      color: $blueLight;
      margin-bottom: 40px;
      @include tablet {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 24px;
      }
    }

  }
  .container_img {
    width: calc(100% + 128px);
    margin-left: -64px;
    @include laptop {
      width: calc(100% + 48px);
      margin-left: -24px;
    }
    @include tablet {
      width: calc(100% + 24px);
      margin-left: -12px;
    }
  }
}
