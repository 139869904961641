@import 'src/assets/styles/palette';
@import 'src/assets/fonts/stylesheet.scss';

html,
body,
div[id="root"] {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  border: 0;
}

body {
  color: $textColor;
  background-color: $violet;
  -ms-overflow-style: none;
  scrollbar-width: 0;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

*,
::after,
::before {
  box-sizing: border-box;
  font-family: Montserrat, serif;
}

h1, h2, h3, h4, h5 {
  margin: 0;
}

p {
  margin: 0;
}

ul, ol {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

a {
  display: inline-block;
  text-decoration: none;
  color: inherit;
}

button {
  font-weight: 700;
  box-shadow: none;
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: none;
  color: inherit;
}
